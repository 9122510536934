import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { css } from "@emotion/core"
import styled from "@emotion/styled"

// cust components
import SakuraText from "./sakura-text"

// images
import ReservationImg from "../../static/assets/homepage-reservation-button-1.png"
import FlowImg from "../../static/assets/homepage_desktop_flow.png"
import FlowVertImg from "../../static/assets/homepage_desktop_vertical_flow.png"

const UnderlineSpan = styled("span")`
  text-decoration: underline;
  color: #e24291;
`

const PadTopBot35 = css`
  padding-top: 35px;
  padding-bottom: 35px;
`

const BookFlowImgCss = css`
  background-image: url(${FlowImg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: 0px;
  border: 0px;
  width: 100%;
  max-width: 1200px;
  height: 200px;
  @media (max-width: 576px) {
     {
      background-image: url(${FlowVertImg});
      width: 100%;
      height: 600px;
      margin-top: 30px;
    }
  }
`

const BookFlow = ({ title, showBanner }) => {
  return (
    <div
      css={css`
        text-align: center;
      `}
    >
      <SakuraText text={title} />
      <Container>
        <Row>
          <Col xl={7} lg={7} md={8} sm={8} xs={12}>
            <div css={PadTopBot35}>
              <p>櫻花家居提供的去甲醛服務彈性靈活</p>
              <p>
                預約去甲醛服務前可按
                <a href="/櫻花除甲醛服務/" title="櫻花除甲醛服務">
                  <UnderlineSpan>這裡</UnderlineSpan>
                </a>
                了解櫻花三種套餐，
                <br /> 看看您的家居最適合那一種。
              </p>
            </div>
          </Col>
          <Col xl={3} lg={3} md={3} sm={3} xs={12}>
            <a href="/booking">
              <img src={ReservationImg} style={{ maxWidth: "200px" }} />
            </a>
          </Col>
        </Row>
      </Container>
      {showBanner ? <div css={BookFlowImgCss} /> : <div />}
    </div>
  )
}

export default BookFlow
