import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { Container, Row, Col } from "react-bootstrap"
import styled from "@emotion/styled"

import { css } from "@emotion/core"

import BookFlow from "../components/book-flow"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SakuraText from "../components/sakura-text"

const PinkSpan = styled("span")`
  color: #ff2ea0;
  font-weight: bold;
  font-size: 23px;
`

const ThePage = () => {
  const data = useStaticQuery(graphql`
    query workQuery {
      prep: file(relativePath: { eq: "work-1-02.png" }) {
        childImageSharp {
          resize(width: 290) {
            src
          }
        }
      }
      before: file(relativePath: { eq: "work-2-01.png" }) {
        childImageSharp {
          resize(width: 290) {
            src
          }
        }
      }
      working: file(relativePath: { eq: "work-3-01.png" }) {
        childImageSharp {
          resize(width: 290) {
            src
          }
        }
      }
      after: file(relativePath: { eq: "work-4-01.png" }) {
        childImageSharp {
          resize(width: 290) {
            src
          }
        }
      }
    }
  `)

  const padBotCss = css`
    padding-bottom: 30px;
  `

  const innerMarginCss = css`
    margin: 30px;
  `

  const whiteBgWrapCss = css`
    background-color: #fdf3f6;
    border-radius: 20px 20px 20px 20px;
  `

  return (
    <Layout>
      <SEO
        title="櫻花除甲醛技術 - 櫻花家居服務"
        description="櫻花除甲醛技術 - 櫻花家居服務"
      />
      <div>
        <Container className="home-banner">
          <Row css={[padBotCss, innerMarginCss]}>
            <Col css={padBotCss} lg={{ span: 6, offset: 1 }} sm={11}>
              <div>
                <h3>
                  <strong>上門除甲醛時，有什麼需要預備和配合？</strong>
                </h3>
              </div>
              <div>
                整個除甲醛流程是怎樣的？客人如何得知室內空間的甲醛指數已達致安全水平？
              </div>
            </Col>
            <Col
              css={padBotCss}
              lg={{ span: 3, offset: 0 }}
              md={{ span: 6, offset: 4 }}
              sm={{ span: 6, offset: 3 }}
              xs={{ span: 6 }}
            >
              <img src={data.prep.childImageSharp.resize.src} />
            </Col>
          </Row>
          <Row css={padBotCss}>
            <Col css={whiteBgWrapCss} xs={12}>
              <Row css={innerMarginCss}>
                <Col lg={{ span: 6, offset: 1 }} md={12}>
                  <p />
                  <p>
                    <strong>
                      <PinkSpan>施工前</PinkSpan>
                    </strong>
                  </p>
                  <p>
                    客人需要將櫃內物件，盡量清空，而需要施工的傢俬表面和空間吸塵和用水作簡單清潔，抹走櫃桶內的木砰屑；灰塵和木碎屑會阻礙甲醛施工液的滲透過程，阻礙藥水發揮作用。
                  </p>
                </Col>
                <Col
                  lg={{ span: 5, offset: 0 }}
                  md={{ span: 5, offset: 3 }}
                  sm={{ span: 6, offset: 3 }}
                  xs={{ span: 6 }}
                >
                  <img src={data.before.childImageSharp.resize.src} />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row css={padBotCss}>
            <Col css={whiteBgWrapCss} xs={12}>
              <Row css={innerMarginCss}>
                <Col
                  lg={{ span: 5, offset: 1 }}
                  md={{ span: 5, offset: 3 }}
                  sm={{ span: 6, offset: 3 }}
                  xs={{ span: 6 }}
                >
                  <img src={data.working.childImageSharp.resize.src} />
                </Col>
                <Col lg={{ span: 6 }} md={12}>
                  <p />
                  <p>
                    <strong>
                      <PinkSpan>施工期間</PinkSpan>
                    </strong>
                  </p>
                  <p>
                    櫻花家居的師傅會把屋內所有抽屜拉出，確保櫃內的甲醛源頭都能消除；櫻花使用專用噴槍把觸媒噴在傢俬、空間、源頭。
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row css={padBotCss}>
            <Col css={whiteBgWrapCss} xs={12}>
              <Row css={innerMarginCss}>
                <Col lg={{ span: 6, offset: 1 }} md={12}>
                  <p />
                  <p>
                    <strong>
                      <PinkSpan>施工後</PinkSpan>
                    </strong>
                  </p>
                  <p>
                    櫻花家居師傅會重覆在各個空間及源頭位置量度TVOC總揮發有機化合物的濃度，確保施工成效。施工完成後，傢俬無需再次清潔，當然，客人若希望清潔亦可，塗層是不會被抹掉，並且持續地發揮作用。
                  </p>
                </Col>
                <Col
                  lg={{ span: 5, offset: 0 }}
                  md={{ span: 5, offset: 3 }}
                  sm={{ span: 6, offset: 3 }}
                  xs={{ span: 6 }}
                >
                  <img src={data.after.childImageSharp.resize.src} />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col fluid>
              <BookFlow title="馬上預約" showBanner={false} />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}
export default ThePage
